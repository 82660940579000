<template>
  <div class="min-h-screen bg-gray-100 flex flex-col justify-center sm:py-12">
    <div class="p-10 xs:p-0 mx-auto md:w-full md:max-w-md">
      <h1 class="font-bold text-center text-2xl mb-5">{{$t("txt-welcome")}}</h1>  
      <h5 class="font-bold text-center text-sm mb-5 text-gray-500" >{{$t("txt-createNewPass")}}</h5>  
      <div class="bg-white shadow w-full rounded-lg divide-y divide-gray-200">
        <div class="px-5 py-7">
          <label class="font-semibold text-sm text-gray-600 pb-1 block">E-mail</label>
          <input disabled autocomplete="new-password" v-model="form.email" type="email" class="rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full" />
          <label class="font-semibold text-sm text-gray-600 pb-1 block">{{$t("txt-newpass")}}</label>
          <input autocomplete="new-password" v-model="form.password" type="password" class="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full" />
          
          <button @click="active" type="button" class="transition duration-200 bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block">
              <span class="inline-block mr-2">{{$t("txt-active")}}</span>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4 inline-block">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
              </svg>
          </button>
        </div>
      </div>
    </div>
  
  </div>
</template>

<script>

export default {
  name: 'Active',
  data: function () {
    return {
      form: {
        email: '',
        password: ''
      },
    }
  },
  methods: {
    async active() {
      const resp = await this.$http.post('/login/active-account', this.form);

      if (!resp.data.success) {
        this.$vToastify.error(this.$t(resp.data.err));
      } else {
        localStorage.setItem('token', resp.data.token);
        localStorage.setItem('user', JSON.stringify(resp.data.user));
        this.$store.commit('login', resp.data.user);
        this.$vToastify.success(this.$t('feedback-actived'));
        this.$router.push({path: '/dashboard'});
      }
    }
  },
  async beforeMount() {

    if (this.$store.state.isLogged) {
      return this.$router.push({path: '/dashboard'});
    }

    const resp = await this.$http.post('/active', { token: this.$route.query.token });

    if (resp.data.success) {

        if (resp.data.user.active) {
          this.$router.push({path: '/dashboard'});
        } else {
          this.form.email = resp.data.user.email;
          this.form.token = this.$route.query.token;
        }
    } else {
      this.$vToastify.error(this.$t(resp.data.err));
      this.$router.push({path: '/login'}).catch(()=>{});
    }
  },
}
</script>
